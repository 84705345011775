import _ from "lodash";

// eslint-disable-next-line import/no-unresolved
import backgrounds from "../../../../../assets/images/backgrounds/*.webp";

/**
 * Picks a random background image for the hero section.
 *
 * @memberof module:Scripts/Home/Helpers
 * @function pickHeroBackground
 * @returns {void} Nothing.
 */
export default () => {
  const hero = document.querySelector("#hero");
  const imagePaths = Object.values(backgrounds);
  const randomPath = imagePaths[_.random(imagePaths.length - 1)];
  const background = `url(${randomPath})`;

  hero.style.backgroundImage = background;
};
