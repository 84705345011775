const _temp0 = require("../../../../../assets/images/backgrounds/aesthetic.webp");
const _temp1 = require("../../../../../assets/images/backgrounds/art.webp");
const _temp2 = require("../../../../../assets/images/backgrounds/cool.webp");
const _temp3 = require("../../../../../assets/images/backgrounds/culture.webp");
const _temp4 = require("../../../../../assets/images/backgrounds/elegance.webp");
const _temp5 = require("../../../../../assets/images/backgrounds/ice-cream.webp");
const _temp6 = require("../../../../../assets/images/backgrounds/noodles.webp");
const _temp7 = require("../../../../../assets/images/backgrounds/vintage.webp");
module.exports = {
  "aesthetic": _temp0,
  "art": _temp1,
  "cool": _temp2,
  "culture": _temp3,
  "elegance": _temp4,
  "ice-cream": _temp5,
  "noodles": _temp6,
  "vintage": _temp7
}