import gsap from "gsap";

/**
 * Animates the navigation bar.
 *
 * @package
 * @memberof module:Scripts/Home/Helpers
 * @function animateNavbar
 * @returns {void} Nothing.
 */
export default () => {
  const config = {
    from: { opacity: 0, pointerEvents: "none" },
    to: {
      opacity: 1,
      pointerEvents: "auto",
      stagger: 0.15,
      duration: 2,
      ease: "power4.inOut",
    },
  };

  gsap.fromTo("header#navbar", config.from, config.to);
};
