/**
 * @file Home page script.
 * @author Victorio Molina <https://github.com/VictorioMolina>
 * @copyright Crush Social Network 2022.
 * @license MIT
 * @module Scripts/Home
 */

import {
  initializeCallToAction,
  registerAnimationPlugins,
  pickHeroBackground,
  animateNavbar,
  animateConceptSection,
} from "./helpers";

/**
 * Main script of the home page.
 *
 * @private
 * @function main
 * @returns {void} Nothing.
 * @requires module:Scripts/Home/Helpers.initializeCallToAction
 * @requires module:Scripts/Home/Helpers.registerAnimationPlugins
 * @requires module:Scripts/Home/Helpers.pickHeroBackground
 * @requires module:Scripts/Home/Helpers.setCopyrightDate
 * @requires module:Scripts/Home/Helpers.animateNavbar
 * @requires module:Scripts/Home/Helpers.animateConceptSection
 */
function main() {
  registerAnimationPlugins();
  initializeCallToAction();
  pickHeroBackground();
  animateNavbar();
  animateConceptSection();
}

main();
