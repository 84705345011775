import gsap from "gsap";

import { getBrowser, isMobile } from "../../../utils/global/functions";

/**
 * Animates the concept section of the page.
 *
 * @package
 * @memberof module:Scripts/Home/Helpers
 * @function animateConceptSection
 * @returns {void} Nothing.
 */
export default () => {
  animateTextBoxes();
  animateBackdrop();
};

/**
 * Animates the Y position of the text containers.
 *
 * @ignore
 * @private
 * @memberof module:Scripts/Home/Helpers
 * @function animateTextBoxes
 * @returns {void} Nothing.
 */
function animateTextBoxes() {
  const config = {
    y: "1.5rem",
    stagger: 0.15,
    ease: "none",
    duration: 1000,
    scrollTrigger: {
      trigger: "section#concept",
      start: "top",
      end: "75%",
      scrub: true,
    },
  };

  gsap.to(".concept__info-box", config);
}

/**
 * Animates the opacity and position of the backdrop images specifically for
 * users navigating with Chrome on a computer.
 *
 * @ignore
 * @private
 * @memberof module:Scripts/Home/Helpers
 * @function animateBackdrop
 * @returns {void} Nothing.
 * @requires module:Global/Functions.isMobile
 * @requires module:Global/Functions.getBrowser
 */
function animateBackdrop() {
  const browser = getBrowser();

  if (isMobile() || browser !== "Chrome") {
    return;
  }

  const config = {
    opacity: 0,
    marginTop: "-10rem",
    stagger: 0.15,
    ease: "sine.inOut",
    scrollTrigger: {
      trigger: "section#concept",
      start: "top",
      end: "50%",
      scrub: true,
    },
  };

  gsap.to(".backdrop > img", config);
}
