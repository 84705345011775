/* eslint-disable no-undef */
import gsap from "gsap";

/**
 * Registers all the required GSAP plugins.
 *
 * @package
 * @memberof module:Scripts/Home/Helpers
 * @function registerAnimationPlugins
 * @returns {void} Nothing.
 */
export default () => {
  gsap.registerPlugin(ScrollTrigger);
};
