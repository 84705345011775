import { redirectToAppStore } from "../../../utils/global/functions";

/**
 * Attaches a click listener to the app download button.
 *
 * @package
 * @memberof module:Scripts/Home/Helpers
 * @function initializeCallToAction
 * @returns {void} Nothing.
 * @requires module:Global/Functions.redirectToAppStore
 */
export default () => {
  const button = document.querySelector("a#cta");

  button.addEventListener("click", redirectToAppStore);
};
